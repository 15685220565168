import { ref } from 'vue';
import { projectStorage,projectAuth } from '../firebase/config';


const use_yerakot_storage = () => {
    const error = ref(null);
  
    // url for download the image
    const url = ref(null);
  
    // Path for firestore
    const filePath = ref(null);
  
    const uploadImage = async (file,barkode,vegName) => {
      filePath.value = `Yerakot/${projectAuth.currentUser.uid}/${barkode}/${vegName}`;
      const storageRef = projectStorage.ref(filePath.value);
  
      try {
        // upload the file
        const res = await storageRef.put(file);
        url.value = await res.ref.getDownloadURL();
      } catch (err) {
        console.log(err.message);
        error.value = err.massage;
      }
    };

    const uploadReport = async(file,branche)=>{
      filePath.value = `Yerakot/reports/${branche}/${file.name}`;
      const storageRef = projectStorage.ref(filePath.value);
  
      try {
        // upload the file
        const res = await storageRef.put(file);
        url.value = await res.ref.getDownloadURL();
      } catch (err) {
        console.log(err.message);
        error.value = err.massage;
      }
    }
  
    // Delete the file
    const deleteImage = async path => {
      const storageRef = projectStorage.ref(path);
  
      try {
        await storageRef.delete();
      } catch (err) {
        console.log(err.message);
        error.value = err.message;
      }
    };


    return { error, url, filePath, uploadImage, deleteImage,uploadReport };
};

export default use_yerakot_storage;