<template>
  <div class="container-items">
    <div class="header-btn">
      <Button
        :label="changeAddItemBtnContect"
        style="width:40%; height:50px; font-size: 1.2rem; margin-bottom:0.5rem; margin: 5px;"
        class="p-button-warning"
        @click="showHideAddNewItemFunc"
      />
      <Button
        v-if="showPrintBtn"
        label="הדפס"
        style="width:40%; height:50px; font-size: 1.2rem; margin-bottom:0.5rem; margin: 5px;"
        @click="printProducts"
      />
      <Button
        v-if="changeAddItemBtnContect != 'סגור'"
        :label="titleBtnForCheckbox"
        class="p-button-danger"
        style="width:40%; height:50px; font-size: 1.2rem; margin-bottom:0.5rem; margin: 5px;"
        @click="handleCheckBox"
      />
    </div>
    <div
      class="btn-for-checks-items"
      style="width:100%; display:flex;"
      v-if="showCheckBox"
    >
      <Button
        class="p-button-danger"
        label="שלח פריטים מסומנים להדפסה"
        style="width:50%; height:50px; font-size: 1.2rem; margin-bottom:0.5rem; margin: 5px;"
        @click="printCheckedItems"
      />
      <Button
        class="p-button-help"
        label="הזמנת סחורה חדשה"
        style="width:50%; height:50px; font-size: 1.2rem; margin-bottom:0.5rem; margin: 5px;"
        @click="handleShowNewDahanOrder"
      />
    </div>
    <div class="btn-for-visible-items" style="width:98%">
      <Button
        v-if="changeAddItemBtnContect != 'סגור'"
        class="p-button-secondary"
        :label="computedNameForVisibleBtn"
        style="width:100%; height:35px; font-size: 1.2rem; margin-bottom:0.5rem; margin: 5px;"
        @click="handleVisibleItems"
      />
    </div>
    <div v-if="showSearch" class="search-bar" style="width:100%">
      <span class="p-input-icon-left" style="width:100%">
        <i class="pi pi-search" />
        <InputText
          type="text"
          v-model="searchString"
          placeholder="חפש"
          style="width:100%"
        />
      </span>
    </div>
    <div v-if="showSortBy" class="sortBy" style="margin-top:0.5rem; width:100%">
      <Dropdown
        v-model="selectedSortBy"
        :options="sortByOptions"
        placeholder="מיין לפי"
        style="width:100%"
      />
    </div>
    <div class="test" style="height:60px;">
      <Button
        v-if="changeAddItemBtnContect != 'סגור'"
        label="עדכן"
        style="width:100%; height:35px; font-size:1.25rem; margin-top:0.5rem;"
        class="p-button-success"
        @click="showStockBeforeUpdate"
      />
    </div>
    <div v-if="showAddNewItem">
      <NewProductVeges @hide="handel_hide_add_item" />
    </div>
    <div v-else style="width:100%; height:60%">
      <div class="product-list-gg">
        <template v-for="product in sortedProducts" :key="product.barkod">
          <div class="product-line-gg" :id="product.barkod">
            <div class="check-box" v-if="showCheckBox">
              <Checkbox
                name="itemChecks"
                :value="product"
                v-model="itemsChecks"
              />
            </div>
            <div class="product-image">
              <img v-if="product.image" :src="product.image" />
              <img
                v-else
                src="https://firebasestorage.googleapis.com/v0/b/rosman2021-d33ae.appspot.com/o/App%2Fnoimg.png?alt=media&token=6fe74e0c-c675-4587-9b50-61ee8fda6892"
              />
            </div>

            <div class="barkod-and-name">
              <div class="product-id" style="">
                {{ product.barkod }}
              </div>
              <div
                class="product-name"
                style="font-size: 1rem; overflow:hidden; "
              >
                <p v-if="product.stock > 0">{{ product.ItemName }}</p>
                <p v-else style="color:red;">{{ product.ItemName }}</p>
              </div>
            </div>

            <div class="icons">
              <div class="update-item">
                <i
                  class="pi pi-pencil"
                  style=" color:black; font-size: 1.2rem;"
                  @click="updateItem(product.barkod.toString())"
                ></i>
              </div>
              <div class="delete-item">
                <i
                  class="pi pi-trash"
                  style=" color:red; font-size: 1.2rem;"
                  @click="
                    deleteItem(product.barkod.toString(), product.filePath)
                  "
                ></i>
              </div>
            </div>

            <div class="product-stock" style="font-size: 1.2rem;">
              <InputText
                type="text"
                v-model="product.tempStock"
                style="width:50%"
                :placeholder="product.openStock"
              />
            </div>

            <i
              v-if="product.visible == 'true'"
              style="color:green;"
              class="pi pi-eye eye-icon"
              @click="handle_visibility_item(product.visible, product.barkod)"
            ></i>
            <i
              v-else
              style="color:red;"
              class="pi pi-eye eye-icon"
              @click="handle_visibility_item(product.visible, product.barkod)"
            ></i>
          </div>
          <p style="color:red; text-align:center;">
            מלאי מעודכן: {{ product.stock }}
          </p>
          <div class="divider-line"></div>
        </template>

        <!-- just for print -->
        <div class="print" ref="print" v-show="false">
          <div class="headlines">
            <p class="barkod" style="width:25%">ברקוד</p>
            <p class="name" style="width:25%">שם מוצר</p>
            <p class="qnt" style="width:25%">כמות</p>
            <p class="qnt" style="width:25%">משקל</p>
          </div>

          <template
            v-for="product in productSortedByDepartments"
            :key="product.barkod"
          >
            <div class="product-line" :id="product.barkod">
              <div class="product-id" style="width:25%;">
                {{ product.barkod }}
              </div>
              <div
                class="product-name"
                style="font-size: 1rem; width:25%; overflow:hidden;"
              >
                <p v-if="product.stock > 0">{{ product.ItemName }}</p>
                <p v-else style="color:red;">{{ product.ItemName }}</p>
              </div>
              <div class="product-stock" style="font-size: 1.2rem; width:25%;">
                <InputText
                  type="text"
                  v-model="amount[product.barkod]"
                  style="width:80%;"
                  :placeholder="product.stock"
                />
              </div>
              <div class="wei" style="width:25%;">
                <InputText type="text" style="width:80%;" />
              </div>
            </div>
          </template>
        </div>

        <!-- just print checked items -->
        <div class="print" ref="printChecked" v-show="false">
          <div class="headlines">
            <p class="barkod" style="width:25%">ברקוד</p>
            <p class="name" style="width:25%">שם מוצר</p>
            <p class="qnt" style="width:25%">כמות</p>
            <p class="qnt" style="width:25%">משקל</p>
          </div>
          <template v-for="product in itemsChecks" :key="product.barkod">
            <div class="product-line" :id="product.barkod">
              <div class="product-id" style="width:25%;">
                {{ product.barkod }}
              </div>
              <div
                class="product-name"
                style="font-size: 1rem; width:25%; overflow:hidden;"
              >
                <p v-if="product.stock > 0">{{ product.ItemName }}</p>
                <p v-else style="color:red;">{{ product.ItemName }}</p>
              </div>
              <div class="product-stock" style="font-size: 1.2rem; width:25%;">
                <InputText
                  type="text"
                  v-model="amount[product.barkod]"
                  style="width:80%;"
                  :placeholder="product.stock"
                />
              </div>
              <div class="wei" style="width:25%;">
                <InputText type="text" style="width:80%;" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div> 
    

    <div v-if="showNewDahanOrder" class="goods-orders slide-in-top">
        <div @click="showNewDahanOrder=!showNewDahanOrder" class="exit-btn">X</div>
       <div id="dahan-order" style="width:100%; height:90%; overflow:hidden; overflow-y:auto;">
        <div>
          <h5>שיווק רוסמן בע"מ 512451451</h5>
          <small>{{ currentDateAndTime }}</small>
        </div>
        <div style="margin-top:5px;">
          <div style="display:flex">
            <p style="width:20%">מזהה מוצר</p>
            <p style="width:20%">שם מוצר</p>
            <p style="width:20%">כמות בהזמנה</p>
            <p style="width:20%">מחיר קנייה</p>
            <p style="width:20%">מחיר מכירה</p>
          </div>
          <template v-for="product in itemsChecks" :key="product.barkod">
            <div style="display:flex; justify-content:center; align-items:center; margin-top:4px;">
              <div style="width:20%; flex-shrink: 0; margin:0">
                {{ product.barkod }}
              </div>
              <div style="width:20%; flex-shrink: 0; overflow:hidden; margin:0">
                <p>{{ product.ItemName }}</p>
              </div>
              <div style="width:20%; flex-shrink: 0;">
                  <InputText
                    v-show="!isPrint"
                    v-model="product.stockOrder"
                    type="text"
                    style="width:70%;"
                    :placeholder="product.stockOrder"
                  />
                <p v-show="isPrint">{{product.stockOrder}}</p>
              </div>
              <div style="font-size: 1.2rem; width:20%; flex-shrink: 0;"></div>
              <div style="width:20%; flex-shrink: 0;"></div>
            </div>
          </template>
        </div>
      </div>
      <div class="goods-orders-btn">
        <Button
          label="שלח להדפסה"
          class="p-button-success"
          style="width:80%;"
          @click="printDahanOrder"
        />
      </div>
    </div>

    <Dialog
      header="עריכת פריט"
      v-model:visible="showEditWindow"
      :modal="true"
      position="top"
    >
      <div
        class="delete-order"
        style="display:flex; flex-direction:column; gap: 15px; width:300px; height:700px; margin-top:1rem;"
      >
        <label>ברקוד:</label>
        <InputText
          type="text"
          v-model="itemToEdit.barkod"
          :placeholder="itemToEdit.barkod"
          style="width:100%; min-width:160px;"
        />
        <label>שם מוצר:</label>
        <InputText
          type="text"
          v-model="itemToEdit.ItemName"
          :placeholder="itemToEdit.ItemName"
          style="width:100%; min-width:160px;"
        />

        <label>מחלקה:</label>
        <Dropdown v-model="itemToEdit.department" :options="depOptions" />

        <label>העלאת תמונה של הפריט</label>
        <input type="file" @change="handleChangeFile" />
        <p style="color: red;">{{ fileError }}</p>
        <!-- @click="updateItemFinish" -->
        <Button
          label="עדכן פריט בשרת"
          style="width:100%; height:40px; font-size: 1.1rem;"
          class="p-button-info"
          @click="updateItemFinish"
        />
      </div>
    </Dialog>

    <Dialog
      header="נא לוודא!"
      v-model:visible="displayConfirmation"
      :style="{ width: '80%' }"
      :modal="true"
    >
      <div class="confirmation-content" style="margin-top:1rem ;">
        <strong>האם אתה בטוח שברצונך לעדכן פרטים אלה?</strong>
      </div>
      <div class="products-before-update">
        <table>
          <tr>
            <th>ברקוד</th>
            <th>שם מוצר</th>
            <th>מלאי לפני עדכון</th>
            <th>מלאי לאחר עדכון</th>
          </tr>
          <template
            v-for="product in productsListBeforeUpdate"
            :key="product.barkod"
          >
            <tr>
              <td>{{ product.barkod }}</td>
              <td>{{ product.ItemName }}</td>
              <td>{{ product.stock }}</td>
              <td>{{ product.tempStock }}</td>
            </tr>
          </template>
        </table>
      </div>

      <div class="dialog-footer">
        <Button
          label="לא"
          @click="displayConfirmation = !displayConfirmation"
          class="p-button-danger"
        />
        <Button label="כן" @click="updateStock" class="p-button-success" />
      </div>
    </Dialog>
  </div>
</template>

<script>
/* eslint-disable */
import use_yerakot_storage from '../../../../../../Methods/use_yerakot_storage'
import FileUpload from 'primevue/fileupload'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import NewProductVeges from './NewProductVeges.vue'
import InputText from "primevue/inputtext";
import {projectFirestore} from '../../../../../../firebase/config'
import Dialog from "primevue/dialog";
import Checkbox from 'primevue/checkbox';
import Dropdown from 'primevue/dropdown';
import swal from 'sweetalert';
export default {
    components:{NewProductVeges,InputText,Dialog,FileUpload,Checkbox,Dropdown},
    setup(){
        const isPrint = ref(false)
        const selectVisibilty=ref('true')
        const visibiltyOptions=ref([
          {name:'כן',value:'false'},
          {name:'לא',value:'true'},
        ])

        const handle_visibility_item=(visible,id)=>{
          let msg="";
          if(visible=='true') msg='האם להסתיר פריט זה?'
          if(visible=='false') msg='האם להציג פריט זה?'
          swal(`${msg}`, {
              buttons: ["לא", "כן"],
          }).then(async res=>{
              let result
              if(res){
                if(visible=='true'){
                  result='false'
                }else{
                  result='true'
                }
                await projectFirestore
                .collection("Applications")
                .doc("4vSEoKeHUrNn3bAFRk5C")
                .collection('Products')
                .doc(id)
                .set({
                  visible:result
                },{merge:true})
              }
          })
        }
        const showVisibleItems = ref(true)

        const handleVisibleItems =ref(()=>{  
          showVisibleItems.value=!showVisibleItems.value
          productsToShow.value=products.value.filter(item=>item.visible==`${showVisibleItems.value}`)
        })

        const computedNameForVisibleBtn=ref(computed(()=>{
          if(showVisibleItems.value){
            return 'הצג פריטים מוסתרים'
          }
          return 'הצג פריטים שאינם מוסתרים'
        }))

        const selectedDep = ref('')
        const showSortBy = ref(true)
        const showSearch = ref(true)
        const printChecked=ref('')
        const showCheckBox=ref(false)
        const itemsChecks=ref([])
        const searchString=ref('')
        const {url,filePath,uploadImage,deleteImage} = use_yerakot_storage()
        const fileError=ref(null)
        const file = ref(null)
        const displayConfirmation=ref(false)
        const productsListBeforeUpdate=ref([])
        const itemToEdit=ref({})
        const showPrintBtn=ref(true)
        const print=ref(null)
        const amount=ref({})
        const products=ref([]);
        const showEditWindow = ref(false);
        const titleBtnForCheckbox=ref("הזמן מלאי")
        const sortByOptions=ref(['שם מוצר','ברקוד','כמות',])
        const selectedSortBy=ref('מיין לפי')
        const showNewDahanOrder=ref(false)
        const depOptions = ref([
          1,2,3,4
        ])
        const handleShowNewDahanOrder=()=>{
            if(itemsChecks.value.length==0){
              swal({
                    title: "עליך לבחור פריטים",
                    text: "לא ניתן להציג פריטים כאשר הם אינם מסומנים",
                    icon: "error",
                    button: "סגור",
                });
            }else{
               showNewDahanOrder.value=!showNewDahanOrder.value
            }

        }

        const handleCheckBox=()=>{
          showCheckBox.value=!showCheckBox.value
          if(titleBtnForCheckbox.value=="הזמן מלאי"){
            titleBtnForCheckbox.value="ביטול"
          }else{
            titleBtnForCheckbox.value="הזמן מלאי"
          }
        }
        var sortedProducts = ref(computed(()=>{
            if(searchString.value==""){
              productsToShow.value = products.value.filter(product=>product.visible==`${showVisibleItems.value}`)
              return productsToShow.value
            }else{
              let temp=[]
              if(Number(searchString.value)>0){
                temp=products.value.filter(product=>product.barkod.includes(searchString.value) && product.visible==`${showVisibleItems.value}`)
                productsToShow.value = temp
                return productsToShow.value
              }else{
                temp=products.value.filter(product=>product.ItemName.includes(searchString.value) && product.visible==`${showVisibleItems.value}`)
                productsToShow.value = temp
                return productsToShow.value
              }
            }
        }));

        let productsToShow = ref([]);


        watch(selectedSortBy, () => {
          if(selectedSortBy.value == 'ברקוד'){
            productsToShow.value.sort((itemA, itemB) => {       
              if(Number(itemA.barkod) < Number(itemB.barkod)){return -1}
              if(Number(itemA.barkod) > Number(itemB.barkod)){return 1}
              return 0
            })
          }
          if(selectedSortBy.value == 'שם מוצר'){
         
            productsToShow.value.sort((itemA, itemB) => {
              if(itemA.ItemName < itemB.ItemName){return -1}
              if(itemA.ItemName > itemB.ItemName){return 1}
              return 0
            })
          }
          if(selectedSortBy.value == 'כמות'){
            productsToShow.value = productsToShow.value.sort((itemA, itemB) => {
              if(itemA.stock < itemB.stock){return -1}
              if(itemA.stock > itemB.stock){return 1}
              return 0
            })
          }
          
        })

        //allowed file types
        const types = ['image/png','image/jpeg','image/jpg']
        const handleChangeFile=(e)=>{
        const selected=e.target.files[0]

          if(selected && types.includes(selected.type)){
            file.value = selected
            fileError.value=null
          }else{
            file.value=null
            fileError.value = 'אנא העלה תמונה בפורמט PNG או JPG'
          }

        }

        const showStockBeforeUpdate=()=>{
          let error=false
          const productsWithTempStock=products.value.filter((product)=>product.tempStock)
          //validation
          productsWithTempStock.forEach(item=>{
            if(isNaN(item.tempStock) || item.tempStock<0){
              swal("אירעה שגיאה", "אחד מהנתונים שהזנת אינו תקין", "error");
              error=true
              return 
            }
          })
          if(!error){
            displayConfirmation.value=!displayConfirmation.value
            productsListBeforeUpdate.value=productsWithTempStock
          }
        }

        const updateStock= ()=>{
        productsListBeforeUpdate.value.forEach(async(product)=>{
            try{
                await projectFirestore
                .collection("Applications")
                .doc("4vSEoKeHUrNn3bAFRk5C")
                .collection('Products')
                .doc(product.barkod.toString())
                .set({
                  stock: product.tempStock,
                  openStock: product.tempStock
                },{merge:true})
 
            }catch(err){
                console.log(err.message);
                swal("אירעה שגיאה", `${err.message}`, "error");
            }       
        })

         swal("עודכן בהצלחה", "עדכנת את המלאים בהצלחה", "success");
          setTimeout(()=>{
            displayConfirmation.value=!displayConfirmation.value
          },1000)
    }

       

        const deleteItem=(id,filePath)=>{
        
        swal({
          title: "האם בטוח שברצנוך למחוק פריט זה?",
          text: "לאחר מחיקה לא ניתן יהיה לשחזר את הפריט!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then(async(willDelete) => {
          if (willDelete) {
                try{

                if(filePath){
                  await deleteImage(filePath)
                }
                
                projectFirestore.
                collection("Applications")
                .doc("4vSEoKeHUrNn3bAFRk5C")
                .collection('Products')
                .doc(id)
                .delete()
            }catch(err){
                console.log(err.message);
            }
          swal("הפריט נמחק בהצלחה", {
          icon: "success",
          });
         } else {
          swal("פריט זה איננו נמחק");
          }
      });

        }

        let snapshot

        const getProducts=()=>{
          snapshot = projectFirestore.
            collection("Applications")
            .doc("4vSEoKeHUrNn3bAFRk5C")
            .collection('Products')
            .orderBy('ItemName')
            .onSnapshot(snapshot =>{
                snapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        products.value.push(change.doc.data())
                    }
                    if (change.type === "modified") {
                        const index = products.value.findIndex(product=>product.barkod == change.doc.data().barkod)
                        if(index!=-1){
                            products.value[index]=change.doc.data()
                        }
                    }
                    if (change.type === "removed") {
                        const index = products.value.findIndex(product=>product.barkod == change.doc.data().barkod)
                        if(index!=-1){
                            products.value.splice(index,1)
                        }
                    }
                })
            })        
        }
        onMounted(()=>{
            getProducts()
        })

        onUnmounted(()=>{
          if(snapshot){
            snapshot()
          }
        })
        
        const showAddNewItem=ref(false)


        // print
        const printProducts=()=>{
            console.log('check');
            var divContents = print.value.innerHTML 
            var a = window.open('', '', 'height=500, width=500'); 
            a.document.write('<html>'); 
            a.document.write('<style>'); 
            a.document.write('*{direction:rtl} .headlines{display: flex; font-weight: bold; } *{font-family: "Rubik", sans-serif;} .product-line {display: flex; align-items:center;}')
            a.document.write('</style>'); 
            a.document.write('<body > <div class="header"><h1 style="text">רשימת פריטים ירקות</h1> </div><br>'); 
            a.document.write(divContents); 
            a.document.write('</body></html>'); 
            a.document.close(); 
            a.print(); 
        }

        // sorted by departments for print 
        const productSortedByDepartments=ref(computed(()=>{
          let temp=[]
          temp=JSON.parse(JSON.stringify(productsToShow.value))
          return temp.sort((a,b)=>{
            return (a.department > b.department) ? 1 : (a.department === b.department) ? ((a.department > b.department) ? 1 : -1) : -1
          })
        }))

        const printDahanOrder=()=>{
          isPrint.value = true
          setTimeout(() => {
              var divContents = document.getElementById('dahan-order')
              var a = window.open('', '', 'height=500, width=500'); 
              a.document.write('<html>'); 
              a.document.write('<style>'); 
              a.document.write('*{direction:rtl} .headlines{display: flex; font-weight: bold; } *{font-family: "Rubik", sans-serif;} .product-line {display: flex; align-items:center;}')
              a.document.write('</style>'); 
              a.document.write('<body > <div class="header"><h1 style="text">רשימת מלאי להזמנה </h1> </div><br>'); 
              a.document.write(divContents.innerHTML); 
              a.document.write('</body></html>'); 
              a.document.close(); 
              a.print();
          }, 500);
          setTimeout(() => {
            isPrint.value = false 
          }, 1000);
        
          
        }

        const currentDateAndTime=ref(computed(()=>{
           var today = new Date();
           return today.toLocaleString(); 
        }))
           
        
        // print only checked items
        const printCheckedItems=()=>{
            if(itemsChecks.value.length==0){
              swal({
                    title: "עליך לבחור פריטים",
                    text: "לא ניתן להדפיס פריטים כאשר הם אינם מסומנים",
                    icon: "error",
                    button: "סגור",
                });
            }else{
                var divContents = printChecked.value.innerHTML 
                var a = window.open('', '', 'height=500, width=500'); 
                a.document.write('<html>'); 
                a.document.write('<style>'); 
                a.document.write('*{direction:rtl} .headlines{display: flex; font-weight: bold; } *{font-family: "Rubik", sans-serif;} .product-line {display: flex; align-items:center;}')
                a.document.write('</style>'); 
                a.document.write('<body > <div class="header"><h1 style="text">רשימת מלאי להזמנה </h1> </div><br>'); 
                a.document.write(divContents); 
                a.document.write('</body></html>'); 
                a.document.close(); 
                a.print(); 
            }
            
        }

        const showHideAddNewItemFunc=()=>{
          showAddNewItem.value = !showAddNewItem.value
          showPrintBtn.value=!showPrintBtn.value
          showSearch.value=!showSearch.value
          showSortBy.value=!showSortBy.value
        }

        const handel_hide_add_item=()=>{
          showAddNewItem.value = false
          showPrintBtn.value=true
          showSearch.value=true
          showSortBy.value=true
        }

        const changeAddItemBtnContect=ref(computed(()=>{
          if(!showAddNewItem.value){
            return "הוסף פריט"
          }else{
            return "סגור"
          }
        }))

        
        const currentBarkod=ref('')
        const updateItem=(id)=>{
           let index = products.value.findIndex(product => product.barkod == id);
           itemToEdit.value = {...products.value[index]};
           currentBarkod.value=itemToEdit.value.barkod
           console.log('curent barkod:',currentBarkod.value);
           console.log(itemToEdit.value);
           showEditWindow.value = true;
        }
        
        const updateItemFinish=async()=>{
          if(file.value){
            await uploadImage(file.value,itemToEdit.value.barkod,itemToEdit.value.ItemName)
            itemToEdit.value.image = url.value
            itemToEdit.value.filePath = filePath.value
          }
          if(itemToEdit.value.barkod==currentBarkod.value){
             try{
                projectFirestore
                .collection("Applications")
                .doc("4vSEoKeHUrNn3bAFRk5C")
                .collection('Products')
                .doc(itemToEdit.value.barkod)
                .set(itemToEdit.value,{merge:true})
                  swal({
                        title: `הפריט עודכן ל${itemToEdit.value.ItemName} בהצלחה`,
                        icon: "success",
                        button: "סגור",
                  }).then(()=>{
                    file.value = null
                    showEditWindow.value=!showEditWindow.value
                  })

              }catch(err){
                swal({
                        title: "אירעה שגיאה בעדכון הפריט",
                        text: `${err.message}`,
                        icon: "error",
                        button: "סגור",
                    });
              }


          }else{
            try{
              projectFirestore.
              collection("Applications")
              .doc("4vSEoKeHUrNn3bAFRk5C")
              .collection('Products')
              .doc(currentBarkod.value)
              .delete()

              projectFirestore
                  .collection("Applications")
                  .doc("4vSEoKeHUrNn3bAFRk5C")
                  .collection('Products')
                  .doc(itemToEdit.value.barkod)
                  .set({...itemToEdit.value})

              swal({
                    title: `הפריט עודכן ל${itemToEdit.value.ItemName} בהצלחה`,
                    icon: "success",
                    button: "סגור",
                });

              }catch(err){
                console.log(err.message);
                swal({
                          title: "אירעה שגיאה בעדכון הפריט אנא פנה למשה",
                          text: `${err.message}`,
                          icon: "error",
                          button: "סגור",
                      });
              }
          }
         
          
        }

        return{
            isPrint,
            handel_hide_add_item,
            productSortedByDepartments,
            selectedDep,
            depOptions,
            printDahanOrder,
            currentDateAndTime,
            showNewDahanOrder,
            handleShowNewDahanOrder,
            currentBarkod,
            computedNameForVisibleBtn,
            showVisibleItems,
            handleVisibleItems,
            selectVisibilty,
            visibiltyOptions,
            showSearch,
            showSortBy,
            selectedSortBy,
            sortByOptions,
            titleBtnForCheckbox,
            printChecked,
            printCheckedItems,
            showCheckBox,
            handleCheckBox,
            itemsChecks,
            searchString,
            fileError,
            handleChangeFile, 
            displayConfirmation,
            showStockBeforeUpdate,
            productsListBeforeUpdate,
            updateItemFinish,
            itemToEdit,
            showEditWindow,
            updateItem,
            showPrintBtn,
            changeAddItemBtnContect,
            showHideAddNewItemFunc,
            showAddNewItem,
            products,
            sortedProducts,
            deleteItem,
            amount,
            updateStock,
            printProducts,
            print,
            productsToShow,
            handle_visibility_item,
            }
    }

}
</script>

<style scoped>
.container-items{
  width: 100%;
  height: 100%;
  padding: 5px;
  overflow: hidden;
  position: relative;
}
.goods-orders{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.goods-orders-btn{
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.exit-btn{
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background: crimson;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}
#dahan-order{
  padding: 20px;
}
#dahan-order::-webkit-scrollbar {
                display: none;
}
.slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

.product-list-gg {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 3px;
  margin-top: 20px;
  flex-shrink: 0;
}
.product-line-gg {
  position: relative;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  align-items: center;
  align-content: start;
  flex-shrink: 0;
  width: 100%;
  height: fit-content;
  margin-bottom: 5px;
}
.eye-icon{
  position: absolute;
  top: 0;
  left:10px;
  font-size: 2rem;
}

.header-btn{
    display: flex;
    justify-content: space-evenly;
    margin-top: 1rem;
}
i{
    cursor: pointer;
}

.products-before-update table{
  margin: 1rem 0;
  font-size: 1.1rem;
  border-collapse: collapse;
  width: 100%;
}

.products-before-update td,th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  text-align: center;
}

.products-before-update table td:last-child,th:last-child{
  color: red;
}
.products-before-update tr:nth-child(even){
  background-color: #dddddd;
}

.dialog-footer{
  display: flex;
  justify-content: space-evenly;
}



.product-image{
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.product-image img{
  width: 80px;
  height: auto;
  max-height: 80px;
}

.barkod-and-name, .icons{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.barkod-and-name{
  color: #333;
  text-align: center;
}




.divider-line{
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.288);
  margin-bottom: 10px;
}

.check-box{
  position: absolute;
  top: 5px;
  right: 5px;
}

@media screen and (max-width: 600px) {

}

</style>