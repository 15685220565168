<template>
  <div class="cost-history">
      <div class="cost-history-box">
          <div class="cost-history-box-header">
              <h4>{{product.ItemName}}</h4>
              <div @click="handle_close" class="exit-btn">
                  <i class="material-icons">close</i>
              </div>
          </div>
          <div class="cost-history-box-content">
              <table>
                  <tr>
                      <th>תאריך עדכון מחיר</th>
                      <th>מחיר</th>
                  </tr>
                  <h1 v-if="history_costs.length==0">אין עדכונים</h1>
                  <template v-for="history in history_costs" :key="history.date">
                      <tr>
                          <td>{{new Date(history.date.seconds*1000).toLocaleDateString('he')}}</td>
                          <td>{{history.cost}} &#8362;</td>
                      </tr>
                 </template>  
              </table>
          </div>
      </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/runtime-core'
export default {
    emits:['close'],
    props:['product'],
    setup(props,{emit}){

        const handle_close = ()=>{
            emit('close')
        } 
        const history_costs = ref(computed(()=>{
            return props.product.history_costs.sort((a,b)=>{
                return new Date(b.date.seconds*1000) - new Date(a.date.seconds*1000)
            }).slice(0,10)
        }))
        
        return{
            history_costs,
            handle_close,
        }
    }
}
</script>

<style scoped>
    .cost-history{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 21;
        padding: 0 5px;
        color: #333;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .cost-history-box{
        width: 100%;
        max-width: 500px;
        height: 50%;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        border-radius: 5px;
        background: #fff;
    }
    .cost-history-box-header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
     .exit-btn{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        background: crimson;
        border-radius: 50%;
        z-index: 21;
        cursor: pointer;
        color: #fff;
    }
    .cost-history-box-content{
        width: 100%;
        height: calc(100% - 50px);
        overflow-y: auto;
        padding: 5px;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;
       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 16px;

    }
    table th {
        padding-top: 4px;
        padding-bottom: 4px;
        text-align: center;
        background-color: var(--secondary);
        color: white;
        position: sticky;
        top: 0;
        font-size: 10px;
        font-weight: 500;
    }
</style>