<template>
  <div class="container-guids">
    <div class="video-guide">
      <iframe
        width="100%"
        height="100%"
        :src="videoCode"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div class="footer-guide">
      <Button
        label="סגור"
        style="width:33.3%; height:60px; margin:5px; font-size: 1rem;"
        class="p-button-danger"
        @click="closeGuid"
      />
      <Button
        label="הבנתי"
        style="width:33.3%; height:60px; margin:5px; font-size: 1rem;"
        class="p-button-success"
        :disabled="disableEvantyBtn"
        @click="handleWhoWatchTheVideo"
      />
      <Button
        label="הדרכות נוספות"
        style="width:33.3%; height:60px; margin:5px; font-size: 1rem;"
        class="p-button-secondary"
        @click="showOrders = !showOrders"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import {projectFirestore,projectAuth,firebase} from '../../../firebase/config'
export default {
  props: ["videoId"],
  emits: ["close"],
  setup(props, { emit }) {
    const videoCode = ref("");
    const disableEvantyBtn = ref(false);

    const closeGuid = () => {
      emit("close");
    };

    const handleWhoWatchTheVideo = async () => {
      const email = projectAuth.currentUser.email;
     await projectFirestore
        .collection("Guides")
        .doc("guides")
        .collection("guides")
        .doc(props.videoId)
        .set({
          watched_by: firebase.firestore.FieldValue.arrayUnion(email)
        },{merge:true});

        closeGuid()
    };

    const getVideo = async () => {
      const email = projectAuth.currentUser.email;
      let isWatch;
      const doc = await projectFirestore
        .collection("Guides")
        .doc("guides")
        .collection("guides")
        .doc(props.videoId).get()
      
      videoCode.value = doc.data().embeded_code;
      isWatch = doc.data().watched_by.includes(email);
      disableEvantyBtn.value = isWatch;
    };

    onMounted(() => {
      getVideo();
    });

    return {
      getVideo,
      videoCode,
      handleWhoWatchTheVideo,
      disableEvantyBtn,
      closeGuid
    };
  }
};
</script>

<style scoped>
.container-guids {
  height: 80vh;
  width: 90vw;
}
.container-guids .video-guide {
  height: 60vh;
}
.container-guids .footer-guide {
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
