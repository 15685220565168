<template>
  <div class="price-list-summary">
      <div class="summary-wrapper">
          <div class="summary-wrapper-header">
              <h4>סיכום פריטים שהוזנו לעדכון</h4>
              <div @click="handle_close" class="exit-btn">x</div>
               <i class="material-icons print-icon" @click="print_table">print</i>
          </div>
          <div class="summary-wrapper-content">
              <table id="table">
                  <tr>
                      <th>ברקוד</th>
                      <th>שם פריט</th>
                      <th>מחיר לפני עדכון</th>
                      <th>מחיר לאחר עדכון</th>
                  </tr>
                  <template v-for="item in summary_data" :key="item.barkod">
                      <tr>
                          <td>{{item.barkod}}</td>
                          <td>{{item.ItemName}}</td>
                          <td>{{item.cost}} &#8362;</td>
                          <td style="color:red;">{{item.temp_price}} &#8362;</td>
                      </tr>
                  </template>    
              </table>
          </div>
          <div class="summary-wrapper-footer">
              <el-button @click="handle_finish" style="width:80%;" type="danger">סיום ועדכון</el-button>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props:['summary_data'],
    emits:['close','finish'],
    setup(_,{emit}){
        const handle_close = ()=>{
            emit('close')
        }

        const handle_finish = ()=>{
            emit('finish')
        }

        const print_table = ()=>{
             var styleElem = document.createElement('style');
            styleElem.type = 'text/css' ;
            var css = `
                 @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');
                *{
                    direction:rtl;
                    font-family: 'Rubik', sans-serif;
                }
                 table {
                    border-collapse: collapse;
                    width: 100%;
                }
                table td, table th {
                    border: 1px solid #ddd;
                    padding: 2px;
                
                }
                table tr:nth-child(even){background-color: #f2f2f2;}
                table tr:nth-child(odd){background-color: #e7e6e6;}
                table tr:hover {background-color: #ddd;}
                table td{
                    text-align: center;
                    color: var(--main);
                    font-size: 16px;

                }
                table th {
                    padding-top: 4px;
                    padding-bottom: 4px;
                    text-align: center;
                    background-color: #333;
                    color: white;
                    position: sticky;
                    top: 0;
                    font-size: 10px;
                    font-weight: 500;
                }
            `
            if(styleElem.styleSheet){
                styleElem.styleSheet.cssText = css;
            }
            else{
                styleElem.appendChild(document.createTextNode(css));
            }
            var divToPrint=document.getElementById("table");
            let newWin= window.open('', '', 'height=500, width=500');
            newWin.document.write(divToPrint.outerHTML);
            newWin.document.getElementsByTagName('head')[0].appendChild(styleElem);
            newWin.document.close();
            newWin.print();
        }
        return{handle_close,handle_finish,print_table}
    }
}
</script>

<style scoped>
    .price-list-summary{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 19;
        padding: 0 5px;
        color: #333;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
    }
    .summary-wrapper{
        width: 100%;
        height: 80%;
        max-width: 500px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        padding: 5px;
    }
    .summary-wrapper-header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .summary-wrapper-content{
        width: 100%;
        height: calc(100% - 100px);
        overflow-y: auto;
    }
    .summary-wrapper-footer{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;
       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 16px;

    }
    table th {
        padding-top: 4px;
        padding-bottom: 4px;
        text-align: center;
        background-color: var(--secondary);
        color: white;
        position: sticky;
        top: 0;
        font-size: 10px;
        font-weight: 500;
    }
     .exit-btn{
        position: absolute;
        top: 5px;
        left: 5px;
        width: 20px;
        height: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 18px;
        background: crimson;
        border-radius: 50%;
        z-index: 20;
        cursor: pointer;
        color: #fff;
    }
    .print-icon{
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 20;
        cursor: pointer;
        font-size: 30px;
        color: cornflowerblue;
      
    }
</style>